import React from "react"
import styled from 'styled-components';
import UAParser from 'ua-parser-js';

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import * as appleImg from '../../images/appstore.png';
import * as playImg from '../../images/googleplay.png';

const StyledBadge = styled.div`
  max-width: 300px;
`;

const OrderPage = () => {
  React.useEffect(() => {
    const parser = new UAParser();
    const os = parser.getOS();
    const device = parser.getDevice();

    if (os.name === 'iOS' && device.type === 'mobile') {
      window.location.replace('https://apps.apple.com/sa/app/leem/id1501163845');
    }

    if (os.name ===  'Android' && device.type === 'mobile') {
      window.location.replace('https://play.google.com/store/apps/details?id=com.leem');
    }
  }, [])

  return (
    <Layout>
      <SEO title="Order" />
      <h1>Oops, please click the link using your mobile device. </h1>
      <h3>You'll then be taken to your mobile app</h3>
      <StyledBadge>
        <a href='https://apps.apple.com/sa/app/leem/id1501163845'>
          <img src={appleImg} alt='appstore' />
        </a>
      </StyledBadge>
      <StyledBadge>
        <a href='https://play.google.com/store/apps/details?id=com.leem'>
          <img src={playImg} alt='google play' />
        </a>
      </StyledBadge>
    </Layout>
  )
}

export default OrderPage
